import React, { useState, useEffect } from 'react';
import {
  Flex,
  Text,
  Skeleton,
  Button,
  useToast,
} from '@sixsense/core/components';
import { SkeletonVariant } from '@sixsense/core/components/Skeleton/constant';
import EditableContent from './EditableContent';
import {
  Edit,
  ThumbsUp,
  ThumbsDown,
  Check,
  XClose,
  Rocket,
} from '@sixsense/core/icons';
import { css } from 'styles/emotion';
import { useRequest } from '@sixsense/core';
import { SummaryGenerationError } from './SummaryGenerationError';
import { FileCorruptedError } from '../commonComponent/FileCorruptedError';

const styles = {
  skeletonSteps: css({
    '> div': {
      padding: '0px 4px 0px 0px',
    },
  }),
  contentStyle: css({
    fontSize: '14px',
    lineHeight: '21px',
    maxHeight: '300px',
    overflow: 'auto',
    marginTop: '8px',
    textWrap: 'wrap',
  }),
  editContentStyle: css({
    border: '1px solid #e2e4e7',
    borderRadius: '6px',
    padding: '12px',
    outline: 'none',
  }),
};
export const SummaryComponent = ({
  product,
  orgId,
  handleUseSummaryBtn,
  setOpenUnsavedChangesModal,
  setIsEditable,
  isEditable,
  updateSharedState,
  productAttributes,
  editableContent,
  setEditableContent,
  setInitialEditableContent,
}) => {
  const [isFeedbackEnabled, setIsFeedbackEnabled] = useState(true);
  const [summary, setSummary] = useState(null);
  const [summaryAPIFailed, setSummaryAPIFailed] = useState(false);
  const [corruptedFileError, setCorruptedFileError] = useState([]);
  // const [editableContent, setEditableContent] = useState(summary?.data?.summary || '');
  const [thumbsUpColor, setThumbsUpColor] = useState('#6C737F');
  const [thumbsDownColor, setThumbsDownColor] = useState('#6C737F');
  const THUMBSUP_GREEN_COLOR = '#12B76A';
  const THUMBSDOWN_RED_COLOR = '#FF0000';
  const FEEDBACK_MSG = {
    thumbs_up: 'Appreciate your response!',
    thumbs_down: 'Appreciate your response! We’ll try to improve..',
  };
  const request = useRequest();
  const toast = useToast();

  const generateSummary = async (excludeFiles ?) => {
    try {
      handleUseSummaryBtn(true);
      if (excludeFiles) {
        // setSummary(null);
        setCorruptedFileError([]);
      }
      const payload = {
        product,
        ...(excludeFiles ? { exclude_error_docs: excludeFiles } : {}),
      };
      const fetchSummaryUrl =
        `sales-ai-settings/${orgId}/admin` +
        `/generate-email-writer-product-summary/?product=${payload.product}`;
      request(fetchSummaryUrl, {
        method: 'POST',
        body: JSON.stringify(payload),
      }).then((data) => {
        setSummary(data);
        setEditableContent(data?.data?.summary);
        setInitialEditableContent(data?.data?.summary);
        handleUseSummaryBtn(false);
      }).catch((error) => {
        if (error?.body?.error_code === 'UPLOADED_DOCUMENT_ERROR') {
          setCorruptedFileError(error?.body?.errors);
        } else {
          setSummaryAPIFailed(true);
        }
      });
    } catch (error) {
      setSummaryAPIFailed(true);
    }
  };

  useEffect(() => {
    generateSummary();
  }, []);

  useEffect(() => {
    // if (isContentEditableField) {
    //   setIsEditable(isEditable);
    // }
    if (isEditable) {
      setIsFeedbackEnabled(false);
    }
  }, [isEditable]);

  const handleContentChange = (newContent: string) => {
    setEditableContent(newContent); // Update the state with the new content
  };

  const handleSaveClick = async () => {
    setIsEditable(false);
    const saveEditedSummaryUrl = `sales-ai-settings/${orgId}/admin/update-email-writer-summary/`;
    const saveEditedSummary = await request(saveEditedSummaryUrl, {
      method: 'POST',
      body: JSON.stringify({
        summary: (editableContent?.replace(/<br\s*\/?>/gi, '\n')),
        product: product || '',
      }),
    });
    if (saveEditedSummary) {
      setInitialEditableContent(editableContent);
      handleUseSummaryBtn(false);
    }
  };

  const sendFeedback = async (feedback) => {
    const feedbackUrl = `sales-ai-settings/${orgId}/admin/feature-feedback/`;
    const feedbackData = {
      source_page: 'email_writer_admin',
      source_meta_data: {
        url: window.location.href,
        id_type: 'summary_id',
        summary_id: summary?.data?.id,
      },
      page_identifier: summary?.data?.id,
      feedback_type: feedback ? 1 : 0,
      feedback: null,
      feature: 'admin_email_writer',
      sub_feature: 'product_summary',
      product,
      tracking_id: summary?.data?.id,
    };

    const feedbackSubmitRequest = await request(feedbackUrl, {
      method: 'POST',
      body: JSON.stringify(feedbackData),
    });
    if (feedbackSubmitRequest?.status === 'ok') {
      if (feedback) {
        setThumbsUpColor(THUMBSUP_GREEN_COLOR);
        setThumbsDownColor('');
      } else {
        setThumbsDownColor(THUMBSDOWN_RED_COLOR);
        setThumbsUpColor('');
      }
      toast.show({
        type: 'icon',
        icon: feedbackData.feedback_type ? ThumbsUp : ThumbsDown,
        iconColor: feedbackData.feedback_type ? 'Success' : 'Warning',
        title: 'Thank you',
        subtitle: feedbackData.feedback_type
          ? FEEDBACK_MSG.thumbs_up
          : FEEDBACK_MSG.thumbs_down,
      });
    } else {
      toast.show({
        type: 'icon',
        icon: Rocket,
        iconColor: 'Error',
        title: 'Oops!',
        subtitle: 'Something went wrong.',
      });
    }
  };

  const generateEmailSkeleton = (widthValue: string, marginenabled = false) => (
    <div className={styles.skeletonSteps}>
      <Skeleton
        variant={SkeletonVariant.RECTANGULAR}
        style={{
          width: widthValue,
          minHeight: '10px',
          marginTop: marginenabled ? '16px' : '0px',
        }}
      />
    </div>
  );

  const summarySkeleton = () => (
    <div>
      <Text size={18} color={'#4B555E'}>
        Generating Summary
      </Text>
      {generateEmailSkeleton('100%', true)}
      {generateEmailSkeleton('100%', true)}
      {generateEmailSkeleton('50%')}
      {generateEmailSkeleton('100%', true)}
      {generateEmailSkeleton('100%', true)}
      {generateEmailSkeleton('50%')}
      {generateEmailSkeleton('100%', true)}
      {generateEmailSkeleton('50%')}
      {generateEmailSkeleton('100%', true)}
      {generateEmailSkeleton('50%')}
    </div>
  );

  const getErrorComponent = () => {
    if (summaryAPIFailed) {
      return (<SummaryGenerationError
        generateSummary={generateSummary}
        setSummary={setSummary}
        setSummaryAPIFailed={setSummaryAPIFailed}
      />);
    } else if (corruptedFileError?.length > 0) {
      return (<FileCorruptedError
        excludeFilesGenerateSummary={generateSummary}
        corruptedFiles={corruptedFileError}
        updateSharedState={updateSharedState}
        uploadedDocuments={productAttributes?.documents}
      />);
    }
    return summarySkeleton();
  };

  const SummarySkeletonUI = getErrorComponent();

  const SummaryUI =
    summary?.status !== 'success' ? (
      <SummaryGenerationError
        generateSummary={generateSummary}
        setSummary={setSummary}
        setSummaryAPIFailed={setSummaryAPIFailed}
      />
    ) : (
      <div>
        <Text size={18} color={'#4B555E'}>
          Summary
        </Text>
        <EditableContent
          isEditable={isEditable}
          onBlur={handleContentChange}
          className={`${styles.contentStyle} ${
            isEditable && styles.editContentStyle
          }`}
        >
          {editableContent}
        </EditableContent>
        <Flex style={{ marginTop: '20px' }} alignItems="center">
          {isEditable ? (
            <Flex gap={20} alignItems="center">
              <Button
                hierarchy="tertiary"
                onClick={() => {
                  // setIsEditable(false);
                  setOpenUnsavedChangesModal(true);
                  setIsFeedbackEnabled(true);
                }}
                leadingIcon={XClose}
                style={{ fontSize: '14px', padding: '0px' }}
              >
                Cancel
              </Button>
              <Button
                hierarchy="link-primary"
                color="blue"
                onClick={handleSaveClick}
                leadingIcon={Check}
                style={{
                  fontSize: '14px',
                  color: '#007BC1',
                  border: 'none',
                  background: 'transparent',
                  padding: '0px',
                }}
              >
                Save
              </Button>
            </Flex>
          ) : (
            <Button
              hierarchy="link-primary"
              color="blue"
              onClick={() => {
                setIsEditable(true);
                handleUseSummaryBtn(true);
              }}
              leadingIcon={Edit}
              style={{
                fontSize: '14px',
                color: '#007BC1',
                border: 'none',
                background: 'transparent',
                padding: '0px',
              }}
            >
              Edit
            </Button>
          )}
          {isFeedbackEnabled && (
            <Flex gap={12} style={{ marginLeft: 'auto' }}>
              <ThumbsUp
                size={16}
                color={thumbsUpColor}
                style={{ cursor: 'pointer' }}
                onClick={() => sendFeedback(true)}
              />
              <ThumbsDown
                size={16}
                color={thumbsDownColor}
                style={{ cursor: 'pointer' }}
                onClick={() => sendFeedback(false)}
              />
            </Flex>
          )}
        </Flex>
      </div>
    );

  return !summary?.status ? SummarySkeletonUI : SummaryUI;
};
